<template>
  <img v-if="!!image" :src="image.url" :alt="alt" class="w-100 pointer max-h-300px" :class="imageClass" :id="image.id"
       data-toggle="modal" :data-target="target" @click="$emit('clicked', $event)">
</template>

<script>
export default {
  props: {
    image: {required: true},
    target: {default: '#bid_images'},
    imageClass: {}
  },
  emits: ['clicked'],
  computed: {
    alt() {
      return !!this.image && !!this.image.name ? this.image.name : 'صورة المزاد';
    }
  }
}
</script>