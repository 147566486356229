<template>
  <div class="container" v-if="!!auction">
    <div class="row justify-content-end" v-if="!!authorized">
      <div class="col-12 px-2">
        <div
          class="w-100 d-flex justify-content-between flex-wrap align-items-center space-x-2 result py-2 pl-3"
        >
          <h4>{{ auction.name }}</h4>
          <div>
            <tooltip title="عرض المزاود الاعلى" v-if="isAdmin">
              <router-link
                v-if="canShowWinner"
                :to="{ name: 'users.edit', params: { user: auction.custom } }"
                target="_blank"
              >
                <i class="fas fa-user-check fa-1-5x text-info"></i>
              </router-link>
              <i
                class="fas fa-user-check fa-1-5x text-info disabled"
                v-else
              ></i>
            </tooltip>
            <tooltip title="تحديث">
              <i
                class="fa fa-sync fa-1-5x text-info pointer"
                @click="refresh"
              ></i>
            </tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 p-1 px-2">
        <base-card card="h-100" body="pm-0" footer="bg-e0 pm-0">
          <template #header>{{ auction.name }}</template>
          <template #body>
            <BidImage :image="mainImage" @clicked="setActiveImage" />
            <h6 class="text-left m-2">
              يتم بيع المعروض على وضعه الراهن و في مكانه الحالي.
            </h6>
            <div class="d-block d-md-none">
              <p v-for="note in notes" :key="note.id" class="w-100 text-left p-2 pre-wrap mb-0">
                {{ note.note }}
              </p>
            </div>
          </template>
          <template #footer>
            <h6 class="text-left m-2">
              <strong>مشاركةالمزاد: </strong>
              <a
                class="mx-1"
                :href="share.facebook"
                target="_blank"
                title="المشاركة عبر فيسبوك"
              >
                <i class="fab fa-facebook fa-1-5x"></i>
              </a>
              <a
                class="mx-1"
                :href="share.whatsapp"
                data-action="share/whatsapp/share"
                target="_blank"
                title="المشاركة عبر واتساب"
              >
                <i class="fab fa-whatsapp-square fa-1-5x text-light-green"></i>
              </a>
            </h6>
          </template>
        </base-card>
      </div>
      <div class="col-md-4 p-1 px-2">
        <base-card card="h-100" footer="bg-e0">
          <template #header>المزاودة</template>
          <template #body v-if="!shouldReload">
            <div v-if="hasPermissionToBid" class="bid-container">
              <div v-if="!authorized">
                <h6 class="text-danger font-weight-bolder">
                  لتتمكن من المزاودة, يرجى تسجيل الدخول
                </h6>
                <router-link
                  class="btn btn-red nr-block mt-2"
                  :to="{ name: 'login' }"
                >تسجيل الدخول
                </router-link
                >
                <router-link
                  class="btn btn-dark nr-block mt-2"
                  :to="{ name: 'register' }"
                >إنشاء حساب
                </router-link
                >
              </div>
              <div v-else-if="stumbled">
                <h6 class="font-weight-bolder text-danger">
                  حسابك متعثر, لا يمكن المزاودة يرجى التواصل مع الإدارة لتسديد
                  المزادات المترتبة عليك
                </h6>
                <router-link
                  class="btn btn-red nr-block mt-2"
                  :to="{ name: 'contact' }"
                >تواصل معنا
                </router-link
                >
              </div>
              <div v-else-if="needActivation">
                <h6 class="font-weight-bolder text-danger">
                  لتتمكن من المزاودة, يرجى تفعيل الحساب
                </h6>
                <router-link
                  class="btn btn-red nr-block mt-2"
                  :to="{ name: 'client.activate' }"
                >تفعيل الحساب
                </router-link
                >
              </div>
              <div v-else-if="notEnoughInsuranceCiel">
                <h6 class="font-weight-bolder text-danger">
                  لتتمكن من المزاودة, يرجى رفع مبلغ التأمين الخاص بك
                </h6>
                <h6 class="color-error-jo p-1">
                  <span>السعر الحالي: </span><span>{{ currentPrice }} </span
                ><span>دينار</span>
                </h6>
                <h6 class="color-success-jo p-1">
                  <span>سقف التأمين: </span
                  ><span>{{ userInsurance.max || 0 }} </span><span>دينار</span>
                </h6>
                <router-link
                  class="btn btn-red nr-block"
                  :to="{ name: 'user.insurance' }"
                >رفع مبلغ التأمين
                </router-link
                >
              </div>
              <div v-else-if="active">
                <h6 class="color-success-jo p-1" v-if="winner">
                  <span v-if="!dateFinished">أنت المزاود الأعلى حالياً</span>
                  <span v-else>تهانينا أنت الفائز</span>
                </h6>
                <h6 class="color-error-jo p-1" v-else>
                  <span v-if="!dateFinished"
                  >أنت لست المزاود الأعلى حالياً</span
                  >
                  <span v-else>حظاً أوفر</span>
                </h6>
                <h6 class="text-bold color-error-jo p-1">
                  <span>السعر الحالي</span>
                  <span class="price">{{ currentPrice }}</span>
                  <span>دينار اردني</span>
                </h6>
                <h6 class="text-bold color-success-jo p-1">
                  <span>أقل قيمة للمزاودة</span><span>{{ lowestBid }}</span
                ><span>دينار اردني</span>
                </h6>
                <div v-if="!dateFinished">
                  <div class="col-sm-12 pm-0 input-group-prepend">
                    <button
                      class="btn btn-red"
                      title="زيادة"
                      @click="plusAction"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                    <input
                      disabled="disabled"
                      class="form-control text-center"
                      :value="inputValue"
                    />
                    <button
                      class="btn btn-red"
                      id="minus_btn"
                      title="تقليل"
                      @click="minusAction"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                  <modal-btn
                    target="#add-bid"
                    btn-class="btn btn-block btn-dark mt-2"
                  >تثبيت المزاودة
                  </modal-btn
                  >
                </div>
              </div>
            </div>
            <div v-else class="bid-container">
              <span class="link danger">لا تملك صلاحية المزاودة</span>
            </div>
          </template>
          <template #footer>
            <div v-if="!dateFinished && !!auction.seconds" class="row">
              <div class="col-sm-12">
                <label>الوقت المتبقي</label>
                <Timer
                  :timer-seconds="auction.seconds"
                  main-box-class="d-flex flex-wrap"
                  t-box-class="flex-fill"
                  :storage-interval-id="storage_interval_id"
                  @timer-finished="redirectHome"
                />
              </div>
            </div>
            <p class="text-center font-weight-bold" v-else>إنتهى الوقت</p>
          </template>
        </base-card>
      </div>
      <div class="col-md-4 p-1 px-2">
        <base-card card="h-100" body="pm-0">
          <template #header>معلومات المركبة</template>
          <template #body>
            <div class="table-responsive">
              <table class="table pm-0 text-center">
                <tr v-if="!!auction.name">
                  <th class="bg-light text-secondary">نوع المركبة</th>
                  <td>
                    <strong>{{ auction.name }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.year">
                  <th class="bg-light text-secondary">سنة الصنع</th>
                  <td>
                    <strong>{{ auction.year }}</strong>
                  </td>
                </tr>
                <tr v-if="!!formattedLicensed">
                  <th class="bg-light text-secondary">مرخصة لغاية</th>
                  <td>
                    <strong>{{ formattedLicensed }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.engine_capacity">
                  <th class="bg-light text-secondary">سعة المحرك</th>
                  <td>
                    <strong>{{ auction.engine_capacity }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.color">
                  <th class="bg-light text-secondary">اللون</th>
                  <td>
                    <strong>{{ auction.color }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.movement_mechanism">
                  <th class="bg-light text-secondary">آلية الحركة</th>
                  <td>
                    <strong>{{ auction.movement_mechanism }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.movement_vector">
                  <th class="bg-light text-secondary">ناقل الحركة</th>
                  <td>
                    <strong>{{ auction.movement_vector }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.oil">
                  <th class="bg-light text-secondary">الوقود</th>
                  <td>
                    <strong>{{ auction.oil }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.car_key">
                  <th class="bg-light text-secondary">المفتاح</th>
                  <td>
                    <strong>{{ auction.car_key }}</strong>
                  </td>
                </tr>
                <tr v-if="!!auction.traveled">
                  <th class="bg-light text-secondary">المسافة المقطوعة</th>
                  <td>
                    <strong>{{ auction.traveled }}</strong>
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </base-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 p-1 px-2 d-none d-md-block" v-if="hasNotes">
        <base-card body="pm-0" card="min-h-250px">
          <template #header>ملاحظات</template>
          <template #body>
            <div class="">
              <p v-for="note in notes" :key="note.id" class="w-100 text-left p-2 pre-wrap">
                {{ note.note }}
              </p>
            </div>
          </template>
        </base-card>
      </div>
      <div class="p-1 px-2" :class="hasNotes ? 'col-md-5' : 'col-md-8'">
        <base-card body="p-0 mb-2" card="min-h-200px">
          <template #header>صور المركبة</template>
          <template #body>
            <div class="container images" v-if="hasSubImages">
              <div class="row text-center text-lg-left">
                <div
                  class="col-md-2 col-md-4 col-sm-3 col-6 p-1"
                  v-for="image in subImages"
                  :key="image.id"
                >
                  <BidImage :image="image" @clicked="setActiveImage" />
                </div>
              </div>
            </div>
            <div v-else class="h-100 d-flex flex-column justify-content-center">
              <h6 class="text-center">لا يوجد صور إضافية</h6>
            </div>
          </template>
        </base-card>
        <BidResults
          v-if="hasNotes"
          :current-price="currentPrice"
          :finish-data="finishData"
          :lowest-bid="lowestBid"
        />
      </div>
      <div class="col-md-4 p-1 px-2" v-if="!hasNotes">
        <BidResults
          :current-price="currentPrice"
          :finish-data="finishData"
          :lowest-bid="lowestBid"
        />
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        v-if="!notEnoughInsuranceCiel"
        @confirmed="addBid"
        target-id="add-bid"
        title="تأكيد المزاودة"
        confirm-text="تأكيد"
        confirm-btn="btn-red"
        cancel-btn="btn-dark"
        :modal-lg="true"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-12 pm-0 d-flex justify-content-center">
              <img
                v-if="!!mainImage"
                :src="mainImage.url"
                class="bid-image pm-0"
                :alt="mainImage.name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pm-0">
              <p class="font-weight-bolder">
                <span
                >بالنقر على الزر أدناه فإنك تلتزم بدفع كامل المبلغ المستحق
                  عليك</span
                >
                ( <span class="link danger">{{ inputValue }}</span> )
                <span> دينار إذا كنت المزاد الاعلى.</span>
              </p>
            </div>
          </div>
        </div>
      </confirm-model>
      <confirm-model
        modal-content="bg-transparent"
        :modal-lg="true"
        footer-classes="hide"
        header-bg="hide"
        body-classes="bg-transparent pm-0"
        target-id="bid_images"
      >
        <div
          id="auction_photos_carousel"
          class="carousel slide w-100"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              v-if="!!mainImage"
              class="carousel-item active"
              :id="`slide_${mainImage.id}`"
            >
              <div class="position-relative w-100">
                <img class="w-100" :src="mainImage.url" :alt="mainImage.name" />
              </div>
            </div>
            <div
              class="carousel-item"
              :class="{ active: !mainImage && index === 0 }"
              v-for="(image, index) in subImages"
              :id="'slide_' + image.id"
              :key="'slide_' + image.id"
            >
              <div class="position-relative w-100">
                <img class="w-100" :src="image.url" :alt="image.name" />
              </div>
            </div>
          </div>
          <a
            class="carousel-control carousel-control-prev"
            href="#auction_photos_carousel"
            role="button"
            data-slide="prev"
          >
            <i class="fa fa-chevron-left fa-2x"></i>
          </a>
          <a
            class="carousel-control carousel-control-next"
            href="#auction_photos_carousel"
            role="button"
            data-slide="next"
          >
            <i class="fa fa-chevron-right fa-2x"></i>
          </a>
        </div>
      </confirm-model>
    </teleport>
  </div>
  <div class="container" v-else-if="!shouldReload">
    <div class="row">
      <div class="col-sm-12 jumbotron shadow">
        <h6 class="text-center">حدث خطأ يرجى إعادة تحميل الصفحة</h6>
        <div class="flex-horizontal-center">
          <button class="btn btn-info" @click="reload">إعادة تحميل</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseCard from "@/components/UI/BaseCard";
import BidImage from "@/components/Pages/Bids/BidImage";
import ModalBtn from "@/components/UI/ModalBtn";
import Timer from "@/components/UI/Timer";
import ConfirmModel from "@/components/UI/ConfirmModel";
import date from "@/common/dateMethods";
import Storage from "@/common/Storage";
import types from "@/common/types";
import UserRole from "@/extra/enums/UserRole";
import Tooltip from "@/components/UI/Tooltip";
import BidResults from "@/components/Bids/BidResults";

export default {
  components: {BidResults, ConfirmModel, Timer, ModalBtn, BidImage, BaseCard, Tooltip},
  data() {
    return {
      auction: "",
      bid: 0,
      maxConcurrentBid: 2000,
      inputValue: 0,
      images: [],
      finishDate: "",
    };
  },
  computed: {
    ...mapGetters([
      "permissions",
      "authorized",
      "active",
      "stumbled",
      "needActivation",
      "isAdmin",
      "isMobile",
      "shouldReload",
    ]),
    auctionId() {
      return this.$route.params.auction;
    },
    mainImage() {
      return !!this.images ? this.images[0] : "";
    },
    subImages() {
      if (!this.mainImage || !this.images) return this.images;
      return this.images.filter(
        (image) => parseInt(image.id) !== parseInt(this.mainImage.id)
      );
    },
    hasSubImages() {
      return !!this.subImages && this.subImages.length > 0;
    },
    share() {
      return {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${document.URL}`,
        whatsapp:
          (this.isMobile ? "whatsapp:/" : "https://web.whatsapp.com") +
          `/send?text=${document.URL}`,
      };
    },
    assistantCanBid() {
      return !(!this.permissions || !types.checkY(this.permissions.bidding));
    },
    hasPermissionToBid() {
      return (
        !this.authorized ||
        [UserRole.Client, UserRole.Admin].includes(this.authorized.role) ||
        (this.authorized.role === UserRole.Assistant && this.assistantCanBid)
      );
    },
    userInsurance() {
      // AUTHORIZED DATA
      const insurance = !!this.authorized ? this.authorized.insurance : false;
      return {
        price: insurance ? insurance.price : 0,
        max: insurance ? insurance.max : 0,
      };
    },
    winner() {
      return (
        !(!this.authorized || !this.auction) &&
        parseInt(this.auction.custom) === parseInt(this.authorized.id)
      );
    },
    lowestBid() {
      return !!this.auction ? parseInt(this.auction.lowest_bid) : 0;
    },
    currentPrice() {
      return !!this.auction ? parseInt(this.auction.current_price) : 0;
    },
    notes() {
      return !!this.auction ? this.auction.auction_notes : [];
    },
    hasNotes() {
      return !(!this.auction || !this.auction.auction_notes || this.auction.auction_notes.length === 0);
    },
    finishData() {
      return !!this.auction
        ? date.withTime(this.auction.finish_date)
        : "";
    },
    formattedLicensed() {
      return !!this.auction
        ? date.withoutTime(this.auction.licensed, true)
        : "";
    },
    notEnoughInsuranceCiel() {
      return (
        !this.isAdmin &&
        !!this.userInsurance &&
        !!this.auction &&
        this.userInsurance.max <= this.currentPrice
      );
    },
    dateFinished() {
      return !this.shouldReload && this.auction.is_finished;
    },
    storage_interval_id() {
      if (!this.auction) {
        return "";
      }

      return `bids-interval-${this.auction.id}`;
    },
    canShowWinner() {
      if (!this.auction || !this.isAdmin) {
        return false;
      }

      return !!this.auction.custom && this.auction.canShowCustom;
    },
  },
  methods: {
    async getAuction() {
      if (!!this.auctionId) {
        const response = await http.send("bids", {
          _method: "GET",
          auction: this.auctionId,
        });
        if (http.responseAccepted(response)) this.setData(response.data);
      }
    },
    setData(data) {
      if (!data) {
        return;
      }

      if (!!data.auction) {
        this.auction = data.auction;
        this.inputValue = this.currentPrice + this.lowestBid;
        this.finishDate = data.auction.finish_date;

        // Images
        const imageable = data.auction.imageable
        if (!!imageable) {
          this.images = imageable.map((image) => {
            return {id: image.id, url: image.url}
          })
        }
      }

      if (!!data.user_status && !!this.authorized) {
        this.authorized.status = data.user_status;
        this.$store.dispatch("setAuthorized", this.authorized);
      }
    },
    plusAction() {
      if (
        !this.isAdmin &&
        this.userInsurance.max <
        parseInt(this.bid) + this.currentPrice + this.lowestBid
      ) {
        return http.errorMessage("وصلت سقف التأمين الخاص بك");
      }

      $_("#minus_btn").attr("disabled", false);

      if (this.maxConcurrentBid > this.bid + this.lowestBid) {
        this.bid += this.lowestBid;
        this.inputValue += this.lowestBid;
      } else {
        http.infoMessage(
          `لا يمكن المزاودة بمقدار اكبر من ${this.maxConcurrentBid} دينار`
        );
      }
    },
    minusAction() {
      if (this.inputValue > this.currentPrice + this.lowestBid) {
        this.bid -= this.lowestBid;
        this.inputValue -= this.lowestBid;
      } else {
        http.infoMessage(`لا يمكن المزاودة بقيمة أقل من ${this.inputValue}`);
      }
    },
    async addBid() {
      this.hideModal("#add-bid");

      if (this.inputValue === this.currentPrice) {
        http.popupMessage("error", "لا يمكن إصافة مزاودة بنفس السعر الحالي");
        return;
      }

      if (!this.authorized) {
        await this.$store.dispatch("clearAuthorized");
        return;
      }

      const response = await http.send("bids", {
        auction: this.auction.id,
        bid: this.inputValue,
      });

      if (http.responseAccepted(response)) {
        Storage.removeStartWith("auction-categories");
        this.bid = 0;
        this.setData(response.data);

        const msg = response.data.msg;
        !!msg
          ? http.errorMessage(msg)
          : http.successMessage("تم إضافة مزاودتك بنجاح");
      }
    },
    connectToAuctionChannel() {
      if (!!this.auction && this.$route.name === "auctions.bid") {
        const channel = `auction.${this.auction.id}`;
        Echo.leave(channel);

        Echo.channel(channel).listen("NewBidEvent", (e) => {
          if (!e || !e.auction) {
            return;
          }

          let auction;
          try {
            auction = JSON.parse(e.auction);
          } catch (e) {
            auction = e.auction;
          }

          if (!auction) {
            return;
          }

          // e variable has other data not just the auction
          if (
            !this.authorized ||
            parseInt(auction.custom) !== parseInt(this.authorized.id) ||
            (!!this.authorized && e.auction_updated)
          ) {
            this.setData({auction});

            if (
              !!this.authorized &&
              e.msg === true &&
              this.$route.name === "auctions.bid"
            ) {
              http.infoMessage("تم إضافة مزاودة جديدة");
            }
          }
        });
      }
    },
    setActiveImage(e) {
      const id = !!e && !!e.target ? e.target.id : "";
      if (!id) return;
      $_(".carousel-item").removeClass("active");
      $_("#slide_" + id).addClass("active");
    },
    redirectHome() {
      this.$router.replace({name: "auctions.index"});
    },
    reload() {
      location.reload();
    },
    async refresh() {
      const response = await http.send(`bids-refresh/${this.auction.id}`, {
        _method: "GET",
      });
      if (http.responseAccepted(response)) {
        const auction = response.data.auction
        auction.auction_notes = this.auction.auction_notes
        response.data.auction = auction

        this.setData(response.data);
      }

      this.connectToAuctionChannel();
    },
  },
  async created() {
    await this.getAuction();

    if (this.dateFinished && !this.isAdmin) {
      return this.redirectHome();
    }

    this.connectToAuctionChannel();
    http.scrollToElement("body");
  },
};
</script>

<style scoped>
.color-success-jo,
.color-error-jo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
  background-color: transparent;
}

.color-success-jo {
  color: #38c172;
  border: 1px solid #38c172;
}

.color-error-jo {
  color: #f44336;
  border: 1px solid #f44336;
}

h6 {
  text-align: right;
}

span,
label {
  padding: 0.25rem;
}

.input-group-prepend button,
.input-group-prepend input {
  border-radius: 0;
}

.bid-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bid-image {
  max-height: 350px;
  max-width: 100%;
}

.images {
  max-height: 200px;
  overflow-y: auto;
}

.carousel-control {
  color: black;
  background-color: rgba(255, 255, 255, 0.2);
}

th,
td {
  width: 50%;
  margin: 0;
}

.price {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .images {
    max-height: 300px;
  }
}

.result {
  border-right: 6px solid #f44336;
}
</style>
